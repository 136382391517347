import React from 'react'
import { Layout } from '@global'
import { Button, Container } from '@UI'

const ErrorPage = () => (
  <Layout>
    <Container
      section
      textBlock
      style={{
        minHeight: '74vh',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f7f8f8',
        marginBottom: '1.6rem',
      }}
    >
      <Container wrapperSm>
        <h1>Page Not Found</h1>
        <p>The page you were looking for does not exist.</p>
        <Button to="/">Please return to the homepage.</Button>
      </Container>
    </Container>
  </Layout>
)

export default ErrorPage
